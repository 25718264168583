import React from 'react'
import Layout from '../components/layout'
import ServicesMenu from './services/_menu.js'

const ProductsPage = () => (

  <Layout bgClass="services">

    <ServicesMenu />

    <div className="details-text">
      
    </div>
  </Layout>
)

export default ProductsPage
